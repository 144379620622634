<template>
  <div class="tw-flex-column tw-h-screen tw-justify-between tw-gap-5">
    <div class="tw-flex tw-flex-row tw-gap-5 tw-ml-5 ">
      <generic-filters @resetFilters="resetFilters">
        <form-field
          label="Zoek adres"
          type="text"
          v-model="queryInput"
          class="tw-w-[250px] tw-mr-5"
        />
        <form-field
          label="Case status"
          nullOption="Alle statussen"
          :options="statusOptions"
          type="select"
          v-model="filter.status"
          class="tw-w-[250px] tw-mr-5"
        />
        <form-field
          label="Woco"
          nullOption="Alle woco's"
          :options="wocos"
          type="multi"
          mode="tags"
          v-model="filter.wocos"
          class="tw-w-[250px] tw-mr-5"
        />
        <form-field
          label="Categorie"
          nullOption="Alle categorieën"
          :options="categories"
          type="select"
          mode="tags"
          v-model="filter.category"
          class="tw-w-[250px] tw-mr-5"
        />
        <form-field
          label="Partner"
          nullOption="Alle partners"
          :options="maintenancePartners"
          type="select"
          mode="tags"
          v-model="filter.partner"
          class="tw-w-[250px] tw-mr-5"
        />
        <form-field
          label="Ticket status"
          nullOption="Alle statuses"
          :options="ticketStatusOptions"
          type="select"
          mode="tags"
          v-model="filter.ticketStatus"
          class="tw-w-[250px] tw-mr-5"
        />
      </generic-filters>
      <div class="tw-w-full tw-flex tw-gap-5 tw-mr-2">

        <div class="tw-my-2 tw-flex-auto tw-flex-col tw-h-full">

          <generic-table
            :columns="columns"
            :data="cases"
            :has-next-page="hasNextPage"
            :is-loading="isLoading"
            :items-amount="numberOfCases"
            @fetchNextPage="fetchNextPage()"
          >
            <template v-slot:buttons>
              <div>
                <button
                  class="tw-bg-green-600 hover:tw-bg-green-500 tw-rounded tw-flex tw-gap-2 tw-items-center tw-p-2 tw-text-white"
                  @click="showModal = true"
                >
                  <PlusIcon class="tw-w-5 tw-h-5" />
                  Nieuwe case
                </button>
              </div>
              <div>
                <button
                  class="tw-bg-blue-500 hover:tw-bg-blue-400 tw-rounded tw-flex tw-gap-2 tw-items-center tw-p-2 tw-text-white"
                  @click="downloadCasesExcel"
                >
                  <DocumentIcon class="tw-w-5 tw-h-5" />
                  Exporteer
                </button>
              </div>
            </template>

            <template v-slot:cell-address="{ row }">
              <router-link
                :to="{
                  name: 'case-detail',
                  params: { id: row.id, type: 'id' },
                }"
                class="tw-text-gray-800 hover:tw-underline"
              >
                {{ row.address }}
              </router-link>
            </template>
            <template v-slot:cell-started_at="{ row }">
              <div v-if="row.opened_at">
                {{ daysSince(row.opened_at) }} dagen
                <span v-if="daysSince(row.opened_at) > 14" class="tw-text-orange-500">&#33</span>
                <span v-if="daysSince(row.opened_at) > 30" class="tw-text-red-500">&#33</span>
              </div>
            </template>

            <template v-slot:cell-current_ticket="{ row }">
              <div v-if="row.current_ticket?.partner" class="tw-flex tw-gap-2">
                <div>{{ row.current_ticket.partner }}</div>
                <div :class="ticketStatusBgColorClass[row.current_ticket.status]" class="tw-text-xs tw-p-1 tw-rounded-sm tw-tracking-wider">
                  {{ ticketStatuses[row.current_ticket.status] }}
                </div>
              </div>
            </template>

            <template v-slot:cell-last_contact_at="{ row }">
              <div v-if="row.last_contact_at">
                {{ daysSince(row.last_contact_at) }} dagen ({{ row.contact_logs_count }})
              </div>
            </template>

          </generic-table>
        </div>
        <div
          class="tw-mr-5 tw-flex-auto tw-flex-col tw-w-[400px] tw-max-w-[400px]"
          v-if="$route.params.id"
        >
          <case-detail :id="$route.params.id" />
        </div>
      </div>
    </div>

    <generic-modal
      v-if="showModal"
      @close="closeModal"
      @submit="createCase"
      :title="route.query.alarmId ? `Nieuwe case voor ${form.address}` : 'Nieuwe case aanmaken'"
      width="'600px'"
    >
      <form-field
        v-if="!route.query.alarmId"
        label="Adres"
        placeholder="Zoek naar een adres"
        :options="addresses"
        type="multi"
        v-model="form.address"
        @search-change="triggerSearch"
        class="tw-w-[250px] tw-mr-5"
      />
      <form-field
        v-if="!route.query.alarmId"
        label="Categorie"
        :options="categories"
        type="select"
        v-model="form.category"
        :nullable="false"
        null-option="Select category"
      />
      <form-field
        type="textarea"
        label="Case Description"
        rows="3"
        v-model="form.description"
      />
    </generic-modal>
  </div>
</template>

<script setup>
import { reactive, computed, ref, watch } from 'vue';
import { useRouter, useRoute } from 'vue-router/composables';
import { watchDebounced } from '@vueuse/core'
import { useQuery, useInfiniteQuery, useMutation, useQueryClient } from '@tanstack/vue-query';
import { actions } from '@/store';
import { notifications } from '@/util';
import fetchData from '@/util/fetch';
import CaseDetail from '@/components/TicketCases/CaseDetail.vue';
import GenericFilters from '@/components/shared/GenericFilters.vue';
import GenericTable from '@/components/shared/GenericTable.vue';
import FormField from '@/components/shared/FormField.vue';
import GenericModal from '@/components/shared/GenericModal.vue';
import { parseISO, differenceInDays } from 'date-fns';
import { maintenancePartners, ticketStatuses, ticketStatusBgColorClass } from '@/constants';
import {
  DocumentIcon,
  PlusIcon,
} from '@vue-hero-icons/outline';

const ticketStatusOptions = Object.entries(ticketStatuses).map(([key, value]) => ({
  value: key,
  label: value,
})).concat({ value: 'no tickets', label: 'Geen tickets' });

const props = defineProps({
  showModal: {
    type: Boolean,
    required: false,
  },
  address: {
    type: String,
    required: false,
  },
  alarmId: {
    type: Number,
    required: false,
  },
});

const router = useRouter();
const route = useRoute();
const queryClient = useQueryClient();
const query = ref('');
const showModal = ref(route.query.showModal || false);

function daysSince(date) {
  return differenceInDays(new Date(), parseISO(date));
}

const form = ref({
  description: '',
  address: route.query.address || '',
  category: ''
});


const filter = reactive({
  status: 'open',
  wocos: [],
  category: '',
  partner: '',
  ticketStatus: '',
  query: '',
});

const resetFilters = () => {
  filter.status = 'open';
  filter.wocos = [];
  filter.category = '';
  filter.partner = '';
  filter.ticketStatus = '';
  filter.query = '';
  queryInput.value = '';
};

const queryInput = ref('')
const statusOptions = ['created', 'open', 'finished', 'cancelled', 'muted'];
const categories = ['Klantenservice', 'KAM', 'O&M']

const getWocos = () => fetchData('/config/api/v1/wocos/?view=full');
const wocosQuery = useQuery({
  queryKey: ['wocos'],
  queryFn: getWocos,
});
const wocos = computed(() =>
  wocosQuery.data?.value?.data.map((w) => ({ value: w.shortname, label: w.name }))
);

watchDebounced(
  queryInput,
  () => {
    filter.query = queryInput.value
  },
  {
    debounce: 300,
  }
)

const perPage = 30;
const token = actions.auth.readToken();

const getList = async ({ pageParam = 1 }) => {
  const { status, wocos, category, partner, ticketStatus, query } = filter;

  const urlParams = new URLSearchParams({
    offset: (pageParam - 1) * perPage,
    limit: perPage,
  });
  if (wocos) {
    wocos.forEach((woco) => {
      urlParams.append('woco_names', woco);
    });
  }
  if (status) {
    urlParams.append('status', status);
  }
  if (category) {
    urlParams.append('category', category);
  }
  if (partner) {
    urlParams.append('current_ticket_partner', partner);
  }
  if (ticketStatus) {
    urlParams.append('current_ticket_status', ticketStatus);
  }
  if (query) {
    urlParams.append('query', query);
  }

  try {
    const result = await fetch(`/tickets/api/v1/cases?${urlParams}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    if (!result.ok) {
      notifications.addNotification({
        message: `Failed to get list of cases: ${result.statusText}`,
        type: 'danger',
      });
      return;
    }
    return await result.json();
  } catch (error) {
    notifications.addNotification({
      message: `Failed to get list of cases, ${e.message || e.detail}`,
      type: 'danger',
    });
    throw new Error('Failed to get list of cases with error: ' + error.message);
  }
};

const {
  data: casesQuery,
  isLoading,
  fetchNextPage,
  hasNextPage,
} = useInfiniteQuery({
  queryKey: computed(() => ['cases', filter]),
  keepPreviousData: true,
  queryFn: getList,
  getNextPageParam: (_, allPages) =>
    allPages[allPages.length - 1].data?.length === perPage ? allPages.length + 1 : undefined,
});

const numberOfCases = computed(() => casesQuery?.value?.pages[0]?.total);
const cases = computed(() => casesQuery?.value?.pages.flatMap((p) => p.data));

async function downloadCasesExcel() {
  const { status, wocos, category, partner, ticketStatus, query } = filter;
  const urlParams = new URLSearchParams({});
  if (wocos) {
    wocos.forEach((woco) => {
      urlParams.append('woco_names', woco);
    });
  }
  if (status) {
    urlParams.append('status', status);
  }
  if (category) {
    urlParams.append('category', category);
  }
  if (partner) {
    urlParams.append('current_ticket_partner', partner);
  }
  if (ticketStatus) {
    urlParams.append('current_ticket_status', ticketStatus);
  }
  if (query) {
    urlParams.append('query', query);
  }

  const result = await fetch(`/tickets/api/v1/cases?view=excel&${urlParams}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (result.ok) {
    await downloadAsFile(result);
  } else {
    const error = await result.json();
    console.error(error);
    notifications.addNotification({
      message: `Something went wrong: ${error.detail}`,
      type: 'danger',
    });
  }
}

async function downloadAsFile(result) {
  const match = /filename="([^"]+)"/.exec(result.headers.get('content-disposition'));
  const filename = match[1];
  console.log(filename);

  const element = document.createElement('a');
  const blob = new Blob([await result.blob()], {
    type: 'application/vnd.ms-excel',
  });
  element.href = window.URL.createObjectURL(blob);
  element.setAttribute('download', filename);
  element.click();
}


const columns = [
  { label: 'Adres', field: 'address' },
  { label: 'Woco', field: 'woco_name' },
  { label: 'Categorie', field: 'category' },
  { label: 'Open sinds', field: 'started_at' },
  { label: 'Case status', field: 'status' },
  { label: 'Laatste ticket', field: 'current_ticket' },
  { label: 'Laatste contactpoging', field: 'last_contact_at' },
];

const { data: addressDetail } = useQuery({
  queryKey: computed(() => ['address', form.value.address, route.query.address]),
  queryFn: async () => {
    const url = `/api/v1/data/address/${form.value.address}`;
    const result = await fetchData(url);
    return result.data;
  },
  refetchOnWindowFocus: false,
  enabled: computed(() => !!form.value.address),
});

const { mutate: createCase } = useMutation({
  mutationFn: () => {
    const payload = {
      address: form.value.address,
      woco_name:  wocosQuery.data?.value?.data.find((woco) => woco.name === addressDetail?.value?.woco)?.shortname,
      description: form.value.description,
      category: form.value.category || 'O&M',
      alarm_id: route.query.alarmId || null,
    };
    return fetchData(`/tickets/api/v1/cases`, {
      method: 'POST',
      body: JSON.stringify(payload),
    });
  },
  onError: (e) => {
    console.error('Error on creating case', e);
    const info = e.message || e.detail || e;
    notifications.addNotification({
      message: `Error occured, ${info}`,
      type: 'danger',
    });
  },
  onSuccess: (data) => {
    router.push({ name: 'case-detail', params: { id: data.data.id } })
    form.value.description = '';
    showModal.value = false;
    queryClient.invalidateQueries({ queryKey: ['cases'] });
    console.log('Case created successfully');
  },
});

const triggerSearch = async (q) => {
  query.value = q;
};

const  addressesQuery = useQuery({
  queryKey: computed(() => ['addresses', query.value]),
  queryFn:  async () => {
    if (!query.value || query.value.length < 1) {
      return [];
    }
    try {
      const result = await fetchData(`/api/v1/clients/query/${query.value}`);
      const data = await result.data;
      return data.map((address) => address.household.address);
    } catch (error) {
      console.error('Error fetching addresses:', error);
      throw error;
    }
  },
  refetchOnWindowFocus: false,
 });

const addresses = computed(() => addressesQuery.data.value);

const openCaseQuery = useQuery({
  queryKey: computed(() => {
    return ['openCase', form.value.address];
  }),
  queryFn: async () => {
    try {
      const urlParams = new URLSearchParams({
        query: form.value.address,
        status: 'open',
      });

      const result = await fetchData(`/tickets/api/v1/cases?${urlParams}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (result.status === 'OK') {
        if (result.data && result.data.length > 0) {
          console.log('Open case found:', result.data);
          notifications.addNotification({
            message: `An open case already exists for the address "${form.value.address}". You cannot create a new case.`,
            type: 'warning',
          });
          form.value.address = '';
          if (route.query.alarmId) {
            showModal.value = false;
            router.push({
            name: 'case-detail',
            params: { id: result.data[0].id, type: 'id' },
          });
          }
          return null;
        }
      }
    } catch (error) {
      notifications.addNotification({
        message: `Checking open cases is broken. You cannot create a new case now.`,
        type: 'danger',
      });
      form.value.address = ''; // Reset the address field to prevent creating a case
      console.error('Error checking for open cases:', error);
    }
    return null;
  },
  refetchOnWindowFocus: false,
  enabled: computed(() => !!form.value.address),
});

function closeModal() {
  showModal.value = false;

  if (route.query.alarmId) {
    router.replace({
    path: route.path,
    query: {}
  });
  }

}

watch(
  () => route.query,
  (newQuery) => {
    if (newQuery.address) {
      triggerSearch(newQuery.address);
    }
  },
  { immediate: true }
);

</script>
