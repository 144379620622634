<template>
  <div>
    <div v-if="isLoading" class="tw-flex tw-gap-5 tw-items-center">
      <div
        class="tw-border-gray-300 tw-m-5 tw-h-10 tw-w-10 tw-animate-spin tw-rounded-full tw-border-8 tw-border-t-green-600"
      />
      Laden...
    </div>
    <div v-else>
      <div class="tw-flex tw-gap-2 tw-flex-cols tw-items-center tw-mb-2 tw-justify-between">
        <div class="tw-my-1">{{ itemsAmountFormatted }} gevonden</div>
        <div class="tw-flex tw-flex-rows tw-gap-2"><slot name="buttons"></slot></div>
      </div>
      <table class="tw-table-auto tw-w-full tw-border-collapse tw-border tw-border-gray-200">
        <thead class="tw-tracking-wider tw-uppercase tw-text-xs">
          <tr>
            <th
              v-for="(column, index) in columns"
              :key="index"
              class="tw-border tw-border-gray-200 tw-p-2 tw-bg-white"
            >
              {{ column.label }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(row, rowIndex) in data"
            :key="rowIndex"
            class="hover:tw-bg-gray-100"
            :class="row.id === $route.params.id && 'tw-bg-gray-200'"
          >
            <td
              v-for="(column, colIndex) in columns"
              :key="colIndex"
              class="tw-border tw-border-gray-200 tw-px-2 tw-min-w-[100px] tw-whitespace-nowrap"
            >
              <slot :name="`cell-${column.field}`" :row="row" :field="column.field">
                {{ row[column.field] || '' }}
              </slot>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="flex" v-show="hasNextPage">
        <div class="tw-mx-auto tw-mt-3 tw-mb-5">
          <button
            @click="$emit('fetchNextPage')"
            class="tw-bg-blue-400 tw-rounded tw-p-1 disabled:tw-bg-gray-100"
          >
            Laad meer...
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue';

defineEmits(['fetchNextPage']);

const props = defineProps({
  columns: {
    type: Array,
    required: true,
  },
  data: {
    type: Array,
  },
  hasNextPage: {
    type: Boolean,
    default: false,
  },
  isLoading: {
    type: Boolean,
  },
  itemsAmount: {
    type: Number,
  },
});

const itemsAmountFormatted = computed(() => {
  if (props.itemsAmount === 1) {
    return '1 resultaat';
  }

  const num = new Intl.NumberFormat('nl-NL', { maximumSignificantDigits: 3 }).format(
    props.itemsAmount,
  )

  return `${num} resultaten`;
});
</script>
